import React from "react"
import Image from "gatsby-image"
import Layout from "../components/layout"

import "./styles.css"
import {graphql} from "gatsby";

export default function BlogEntry({ pageContext, data }) {
  const bannerShadow = data.bannerShadow.childImageSharp.fluid
  return (
    <Layout>
      <div className="background-image">
        <Image fluid={pageContext.banner_image.childImageSharp.fluid} className="background-image-medium banner-header-background" />
        <h1 className="banner-header-text">{pageContext.title}</h1>
        <Image fluid={bannerShadow} className="background-rounded-shadow" style={{ position: "absolute" }} loading="eager" />
      </div>
      <div style={{margin: 'auto'}} className="content-container details-container">
        <div className='text-section'>
          {pageContext.sections.map((section, index) => {
            switch (section.type) {
              case 'header':
                return <h2 key={index} dangerouslySetInnerHTML={{__html: section.content}}/>
              case 'paragraph':
                return <div className='paragraph' key={index} dangerouslySetInnerHTML={{__html: section.content}}/>
              case 'list':
                return <div className='paragraph' key={index}><ul>{section.list.map((c, i) => <li key={`${index}-${i}`} dangerouslySetInnerHTML={{__html: c}}/>)}</ul></div>
              case 'image':
                return <div className='paragraph' key={index}><Image fluid={section.image.childImageSharp.fluid}/></div>
              default:
                return <div key={index} dangerouslySetInnerHTML={{__html: section.content}}/>
            }
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    bannerShadow: file(relativePath: {eq: "images/banner_header_shadow.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
